import React, { useCallback, useState, useEffect } from 'react';
import './App.css';
import { useDropzone } from 'react-dropzone';
import { Button, Switch, Card, Typography, Layout, Steps, Form, InputNumber, Select, Space, Divider, List } from 'antd';
import { BulbOutlined, QuestionCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { updateFirebaseDoc } from './firebaseConfig';
import QRCode from "react-qr-code";
const { Title, Paragraph } = Typography;
const { Step } = Steps;
const { Header } = Layout;

export function CreateForm({ onSubmit, handleGoogleSignIn, user, isChromeExtension }) {
  const [currentStep, setCurrentStep] = useState(0);
  const [files, setFiles] = useState([]);
  const [gender, setGender] = useState('female');
  const [height, setHeight] = useState({ feet: 5, inches: 5 });
  const [weight, setWeight] = useState(160);
  const [shoeSize, setShoeSize] = useState(9);
  const [fitPreference, setFitPreference] = useState('tight-fit');
  const [error, setError] = useState(null);
  const [welcome, setWelcome] = useState(false);
  const [isInches, setIsInches] = useState(true);
  const [isSmallScreenHeight, setIsSmallScreenHeight] = useState(window.innerHeight < 700 || window.innerWidth < 200);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreenHeight(window.innerHeight < 700 || window.innerWidth < 200);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleHeightChange = (type, value) => {
    setHeight(prev => ({ ...prev, [type]: value }));
  };

  const onDrop = useCallback(acceptedFiles => {
    setFiles(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/*',
    maxFiles: 1
  });

  const thumbs = files.map(file => (
    <div style={{ height: '35vh', width: '100%' }} key={file.name}>
      <img width="100%" src={URL.createObjectURL(file)} alt="preview" className="thumb-image" />
      <Button type="primary" onClick={() => {
        setFiles(prev => prev.filter(f => f !== file));
      }}>
        Remove
      </Button>
      <br></br>
    </div>
  ));

  const handleSubmit = (e) => {
    e.preventDefault();
    let finalHeight = height.inches;
    let finalWeight = Math.round(weight * 0.45359237);
    if (isInches) {
      finalHeight = (height.feet * 12 + height.inches) * 2.54;
    }
    if (files.length === 0) {
      setError("Please upload at least one photo.");
      return;
    }
    const formData = {
      gender,
      height: finalHeight,
      weight: finalWeight,
      shoeSize,
      fitPreference,
      images: files
    };
    if (typeof onSubmit === 'function') {
      onSubmit(formData);
    } else {
      console.error("onSubmit prop is not a function");
    }
    updateFirebaseDoc(user, {gender: gender, height: finalHeight, weight: finalWeight , shoe_size: shoeSize, fit_preference: fitPreference})
  };

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const prevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const data = ['Wear form-fitting clothes & ensure yout body is fully in frame of photo.', 'For best results, stand in A-Pose in front of a well-lit, neutral backdrop'];

  return (
    <>
     {/* <Title level={5}>Create an Xperience profile</Title> */}
    <br/>
    {!isSmallScreenHeight && (
        <div>
          <Steps size='small' type='default' labelPlacement="vertical" current={currentStep}>
            <Step subTitle="Welcome" />
            <Step subTitle="Upload Photo" />
            <Step subTitle="Submit" />
          </Steps>
          <br></br>
        </div>
      )}
      {currentStep === 0 && (<>
        {welcome ? 
        <>
        <Card hoverable title="Instructions: Reference photo">
        <List
          size="small"
          header={<center><div style={{ maxWidth: '100%', height:'150px', display:'block'}}><img alt='instructions' style={{ height:'100%'}}  src='https://me.meshcapade.com/images/from-photos/a-pose.gif' /></div></center>}
          footer={<Button type='link' target='_blank' href='https://me.meshcapade.com/images/from-photos/sample-image.jpg'>View sample reference photo</Button>}
          bordered
          dataSource={data}
          renderItem={(item) => <List.Item>{item}</List.Item>} 
          />
          <br></br>
        <Space>
            <Button type="secondary" onClick={()=>{setWelcome(false)}}>Cancel</Button>
            <Button type="primary" onClick={nextStep}>Got it, ready  →</Button>
        </Space>
        </Card>
        </> :
        <Card hoverable title="Your AI-Powered Fit Analyzer">
          {/* <Paragraph>Set up your account using a single photo!</Paragraph> */}
          <center>
            <img 
              src='https://uploads-ssl.webflow.com/61a5cdfe7fc055e2d1b7141b/61cacacf43a7568cc4d272d9_ezgif-5-8b2690836f.gif'
              style={{width: '60%',}}
              alt='XperienceLottie'
              />
              </center>
          <hr></hr>
            <Paragraph>Using a reference photo, we'll use computer vision and AI to calculate your  body measurements based on a virtual 3D avatar to provide ultra-precise size recommendations.</Paragraph>
            <Button type="primary" onClick={()=>{setWelcome(true)}}>Get Started  →</Button>
          </Card>}
          <br></br>
         { user.googleUser ? <Paragraph> Logged in with Google: {user.email}</Paragraph> : <Space style={{marginLeft:15}}>Made an account already?  → <Button onClick={handleGoogleSignIn} type="link">Login</Button></Space>}
          <br></br><br></br><br></br>
       </>
      )}

      {currentStep === 1 && (<>
        <Card hoverable title="Submit your reference photo">
        {files.length > 0 ? 
            <center>{thumbs}<br></br><p>Photo selected👍</p></center>
            :
            <>
              <div {...getRootProps({ className: 'dropzone' })}>
              <input {...getInputProps()} />
              <br></br>
              <Paragraph>Drag & Drop reference here</Paragraph>
              <Paragraph>or</Paragraph>
              <Paragraph>Click to upload reference photo</Paragraph>
              </div>
            </>
        }
        <Divider/>
          <Space>
            <Button type="secondary" onClick={prevStep}>Back</Button>
            <Button type="primary"  disabled={files.length > 0 ? false : true } onClick={nextStep}>Next</Button>
          </Space>
        </Card>
        <br></br>
        <br></br>
        <br></br>
        </>
      )}
      {currentStep === 2 && (
        <><Card hoverable >
                    <Form>
                    <Space>
                      <Form.Item
                          label="Height"
                          rules={[
                              {
                                  required: true,
                                  message: 'Please input your height!',
                              },
                          ]}
                      >
                          {isInches ? (
                              <>
                                  <InputNumber
                                      min={4}
                                      max={7}
                                      value={height.feet}
                                      onChange={value => handleHeightChange('feet', value)}
                                      step={1}
                                      style={{ margin: '0 10px' }} />
                                  ft
                                  <InputNumber
                                      min={0}
                                      max={11}
                                      value={height.inches}
                                      onChange={value => handleHeightChange('inches', value)}
                                      step={1}
                                      style={{ margin: '0 10px' }} />
                                
                              </>
                          ) : (
                              <InputNumber
                                  min={100} // 4 feet in cm
                                  step={10}
                                  value={weight}
                                  onChange={value => setWeight(value)}
                                  style={{ margin: '0 10px' }} />
                          )}
                      </Form.Item>
                      <Form.Item>
                          <Switch
                              checked={isInches} // Adjusted for default inches
                              checkedChildren="inches"
                              unCheckedChildren="cm"
                              onChange={checked => setIsInches(checked)} />
                      </Form.Item>
                      </Space>
                      <Form.Item
                          label="Weight"
                          rules={[
                              {
                                  required: true,
                                  message: 'Please input your weight!',
                              },
                          ]}
                      >
                          <InputNumber
                              required
                              min={50}
                              value={weight}
                              onChange={setWeight}
                              step={10}
                              style={{ margin: '0 10px' }} />
                          lbs
                      </Form.Item>
                      <Form.Item
                          label="Shoe Size (US)"
                          rules={[
                              {
                                  required: true,
                                  message: 'Please input your shoe size!',
                              },
                          ]}
                      >
                          <InputNumber
                              required
                              min={4}
                              value={shoeSize}
                              onChange={setShoeSize}
                              step={1}
                              style={{ margin: '0 10px' }} />
                      </Form.Item>
                      <Form.Item
                          label="Style"
                          required
                          value={gender} // Set the value of the Select component
                          onChange={(value) => setGender(value)} // Update the gender state when the value changes
                          rules={[
                              {
                                  required: true,
                                  message: 'Specify gender option',
                              },
                          ]}
                      >
                     <Select
                              required
                              placeholder="Which dept of apparel do you shop in?"
                              value={gender} // Set the value of the Select component
                              onChange={(value) => setGender(value)} // Update the gender state when the value changes
                    >
                              <Select.Option value="male">Men's</Select.Option>
                              <Select.Option value="female">Women's</Select.Option>
                              <Select.Option value="other">Non-Binary</Select.Option>
                          </Select>
                      </Form.Item>
                      <Form.Item
                          label="Fit Preference"
                          required
                          value={shoeSize} // Set the value of the Select component
                          onChange={(value) => setFitPreference(value)} // Update the gender state when the value changes
                          rules={[
                              {
                                  required: true,
                                  message: 'Specify fit preference',
                              },
                          ]}
                      >
                          <Select
                              required
                              placeholder="In general, how do you like your clothes to fit?"
                              value={fitPreference} // Set the value of the Select component
                              onChange={(value) => setGender(value)} // Update the gender state when the value changes
                          >
                              <Select.Option value="tight-fit">Tight</Select.Option>
                              <Select.Option value="form-fitting">Comfy</Select.Option>
                              <Select.Option value="comfortable-fit">Loose</Select.Option>
                          </Select>
                      </Form.Item>
                  </Form>
                
              
         <Divider/>
              <Space>
                <Button type="secondary" onClick={prevStep}>Back</Button>
                <Button type="primary"  disabled={weight && height && gender ? false : true } onClick={handleSubmit}>Submit</Button>
              </Space>
        <br></br>
        </Card>
        <br></br>
        <br></br>
        </>
      )}
    </>
  );
}
