import React, { useEffect, useState } from 'react';
import {  Table, Button, Card, Tabs, Progress} from 'antd';
import { TypeAnimation } from 'react-type-animation';
import Paragraph from 'antd/es/typography/Paragraph';
import './App.css';
import { getAvatarData } from './utils';
import Title from 'antd/es/typography/Title';

export function Texts(texts, timer) {
    const [currentIndex, setCurrentIndex] = useState(0);
   
    useEffect(() => {
        const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % texts.length);
        }, timer);
        // Clean up the interval when the component is unmounted
        return () => clearInterval(interval);
    }, []);

        return texts[currentIndex];
}


export const Video = () => {
  return (
    <div style={{width:'100%', height: '30vh', marginTop:"-5px",  overflow:'hidden'}}>
    <iframe width="100%" height="100%" style={{objectFit:'fill'}} src="https://www.youtube.com/embed/_TKzuv0fQwo?si=Z5hRLE4FTU2urpsK" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    </div>
  );
};

export function LoadingPage({ result, onBack, darkMode }) {
    // console.log(result);
    const [currentPercentage, setCurrentPercentage] = useState(0);  
    const sorryText = ["Creating your 3D avatar, please wait.","Processing... please don't refresh", "Analyzing photo submission...", "Setting up avatar with inputs....", "Creating your virtual avatar mesh...", "Three minutes now will save hours later, trust.", "Please wait...", "Putting some spit on it...", "Trying elbow grease!", "AI magic on the backend!", "Thank you for your patience!"];  

    // const dataSource = Object.entries(result).filter(([key, _]) => key !== 'images').map(([key, value], index) => {
    //     if (Array.isArray(value)) {
    //         value = value.join(', '); // Convert array to comma-separated string
    //     }
    //     return {
    //         key: index,
    //         field: key,
    //         value: value
    //     };
    // });
    
    
    // const columns = [
    //     {
    //         title: 'Field',
    //         dataIndex: 'field',
    //         key: 'field',
    //     },
    //     {
    //         title: 'Value',
    //         dataIndex: 'value',
    //         key: 'value',
    //         render: (value, record) => {
    //             if (record.field === 'images' && Array.isArray(value)) {
    //                 // Render the images or provide links to view them
    //                 return value.map((imgSrc, idx) => (
    //                     <a key={idx} href={imgSrc} target="_blank" rel="noopener noreferrer">
    //                         <img src={imgSrc} alt={`Image ${idx + 1}`} style={{ width: '50px', marginRight: '10px' }} />
    //                     </a>
    //                 ));
    //             }
    //             // Add conditions for other fields with arrays if needed
    //             return value; // For other fields, just display the value
    //         }
    //     }
    // ];


    // function ImageCard({ data }) {
    //     const [imgSrc, setImgSrc] = useState(null);
    
    //     useEffect(() => {
    //         if (data.images && data.images.length > 0) {
    //             const file = data.images[0];
    
    //             const reader = new FileReader();
    //             reader.onload = function(event) {
    //                 setImgSrc(event.target.result);
    //             };
    //             reader.readAsDataURL(file);
    //         }
    //     }, [data]);
    
    //     return (
    //         <Card
    //             cover={<img alt="example" src={imgSrc} />}                
    //         >
    //         </Card>
    //     );
    // }
   
    useEffect(() => {
        const increment = 0.0281444;
        const maxPercentage = 100;

        const interval = setInterval(() => {
            if (currentPercentage < maxPercentage - increment) {
                setCurrentPercentage(prev => prev + increment);
            } else {
                setCurrentPercentage(maxPercentage);
                clearInterval(interval);
            }
        }, 100);

        return () => clearInterval(interval); // Clear the interval when the component unmounts
    }, [currentPercentage]);

    // const items = [
    //     {key: '1', label:'Parameters', children: <Table pagination={false} dataSource={dataSource} columns={columns} /> },
    //     {key: '2', label:'Image', children: <ImageCard data={result}/> }
    // ];

    return (
        <div style={{width: '100%',
        height: '100%'}}>
            {/* <br></br>
            <Button type="default" onClick={onBack}>
            Cancel
          </Button> */}
          {/* <Button onClick={getAvatarData}>DEBUG ME</Button> */}
            {/* <br></br> */}
            {/* <Card title={Texts(sorryText, 19000)}>
                <Card type='inner'><Paragraph type='secondary'>Creating your 3D avatar and calculating your body measurements virtually. Please do not refresh. This screen will update automatically when ready.</Paragraph></Card>
            </Card> */}
            <Card hoverable title="Did you know?">         
            <div style={{maxWidth:'100%'}}>
            <TypeAnimation
              style={{
                display: 'block',
              }}
              wrapper='div'
            omitDeletionAnimation="true"
            sequence={[
                "Fast fashion refers to the rapid production of high volumes of low-priced clothing, reflecting the latest trends. This quick turnover encourages consumers to purchase more frequently, leading to higher waste.",
        5000,"The fashion industry is the second-largest consumer of water globally. Producing one cotton t-shirt requires about 2,700 liters of water, equivalent to what an average person drinks over 2.5 years.",
        5000, "The dyeing and treatment of garments make the fashion industry the second-largest polluter of clean water after agriculture.",
        5000,"Over 85% of textiles end up in landfills or are incinerated when most of these materials could be reused.",
        5000,"The fashion industry is responsible for 10% of global carbon emissions, more than all international flights and maritime shipping combined.",
        5000,"Fast fashion often uses polyester, a synthetic fiber derived from petroleum. Its production emits 2-3 times more carbon than cotton.",
        5000,"Fast fashion items are often designed to be worn less than 10 times before they fall apart.",
        5000,"Brands overproduce by 30-40%, leading to unsold items that often end up in landfills.",
        5000,"E-commerce has a return rate of around 20-30%, much higher than brick-and-mortar stores which average 8-10%.",
        5000,"The process of returning goods involves transportation, often by air, which significantly increases carbon emissions.",
        5000,"Many returned items don't make it back to the shelves. Some estimates suggest that in the U.S., 5 billion pounds of returned goods end up in landfills annually.",
        10000,"E-commerce relies heavily on packaging, which often becomes waste. Returns amplify this issue as items are often over-packaged to ensure they aren’t damaged in transit.",
        5000,"Returns cost U.S. retailers around $369 billion in lost sales in 2018.",
        5000,"Processing returns requires energy and resources, from repackaging to restocking or disposing of items.",
        5000,"Returned items, if not in perfect condition, are often sold at a discount or discarded, leading to financial losses and environmental harm.",
        5000,"The back-and-forth shipping of returns increases the number of vehicles on the road, contributing to air pollution and traffic congestion.",
        5000,"The ease of online returns has unintentionally encouraged a 'buy-use-return' culture, where consumers purchase items with the intention of using them briefly and then returning them.",
        1000,"The cost of handling returns can lead to financial strain for businesses, potentially resulting in layoffs or reduced hours for employees.",
        5000,"Warehouses dedicated to processing returns consume energy for operations, including lighting, heating, and cooling.",
        5000,"The high cost of returns can make some e-commerce models unsustainable in the long run, leading to business closures and economic disruptions."
            ]}
            cursor={true}
            />
            </div>
            </Card><br></br>
          <Card hoverable>
                {/* <img width={'100%'} height={'100%'} style={{objectFit:'cover'}} alt='loading animation' src={Texts(images1, 10000)}/> */}
                <Video />
            <br />
            <center>
              <Paragraph>{Texts(sorryText, 7500)}</Paragraph>
              <Progress type='line' size='default' percent={Math.round(currentPercentage)}  strokeColor={darkMode ? "#ffffff":"#000000"} />
              <Paragraph>Time left: ~ {(2.5 - (2 * (currentPercentage/100))).toFixed(1)} min left </Paragraph>
            </center>
           
            {/* <div style={{width:'100%', height: '10vh', overflow:'hidden'}}>
                <img width={'100%'} height={'100%'} style={{objectFit:'cover'}} alt='loading animation' src={Texts(images2, 10000)}/>
            </div> */}
            </Card>
            
            {/* <br></br>
            <Card hoverable title={'Your inputs'}>
            <Tabs
                defaultActiveKey="1"
                type="card"
                size={'small'}
                items={items}
            />
        </Card> */}
        <br />
         </div>
    );
}