import React, { useState, useEffect } from 'react';
import { Tabs, Button, Spin, Card, Divider, Input } from 'antd';
import { ViewerPage } from './AvatarView';  // Assuming your ViewerPage is in ViewerPage.js
import { SizeRec } from './SizeRecommendation';
import { SafetyCertificateTwoTone, BulbTwoTone, ClockCircleOutlined } from '@ant-design/icons';
import { TypeAnimation } from 'react-type-animation';
import { MeasurementsTable } from './Measurements';
import { LoadingPage } from './LoadingForm';

export function TabbedResults(props) {
    const [activeKey, setActiveKey] = useState('1');
    const { data } = props;
    const { FabMenu } = props;
    const [isSmallScreenHeight, setIsSmallScreenHeight] = useState(window.innerHeight < 700 || window.innerWidth < 700);

    const handleBack = () => {
        setActiveKey('1');
    };

    useEffect(() => {
      const handleResize = () => {
        setIsSmallScreenHeight(window.innerHeight < 700 || window.innerWidth < 700);
      };
  
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
    
    const SummaryView = ({ summary }) => {

    //   const parsedArray = JSON.parse(summary);
    //   function isValidJsonArray(str) {
    //     try {
    //         var obj = JSON.parse(str);
    //         return Array.isArray(obj);
    //     } catch (e) {
    //         return false;
    //     }
    // }    
        return (
          <>
            {summary && <Card title="Body Summary" bordered={true} style={{ width: '100%', height: '100%' }}>
              {summary.map((item, index) => {
                //  console.log(item);
                 return(
                  <>
                <TypeAnimation cursor={false} sequence={[item]}/>
                <br/><br/>
                </>
                 );
              })}
            </Card>}
            <br />
          </>
        );
      };

      const MeasurementView = () => {
            return (
              <>
             {data &&   <MeasurementsTable isSmallScreenSize={isSmallScreenHeight} measurements={data.measurements}  /> }
              </>
            );
          };

    const MySettings = (result) => {
        const dataSource = Object.entries(result).filter(([key, _]) => key !== 'images').map(([key, value], index) => {
            if (Array.isArray(value)) {
                value = value.join(', '); // Convert array to comma-separated string
            }
            return {
                key: index,
                field: key,
                value: value
            };
        });
        
        
        const columns = [
            {
                title: 'Field',
                dataIndex: 'field',
                key: 'field',
            },
            {
                title: 'Value',
                dataIndex: 'value',
                key: 'value',
                render: (value, record) => {
                    if (record.field === 'images' && Array.isArray(value)) {
                        // Render the images or provide links to view them
                        return value.map((imgSrc, idx) => (
                            <a key={idx} href={imgSrc} target="_blank" rel="noopener noreferrer">
                                <img src={imgSrc} alt={`Image ${idx + 1}`} style={{ width: '50px', marginRight: '10px' }} />
                            </a>
                        ));
                    }
                    // Add conditions for other fields with arrays if needed
                    return value; // For other fields, just display the value
                }
            }
        ];
    
    
        function ImageCard({ data }) {
            const [imgSrc, setImgSrc] = useState(null);
        
            useEffect(() => {
                if (data.images && data.images.length > 0) {
                    const file = data.images[0];
        
                    const reader = new FileReader();
                    reader.onload = function(event) {
                        setImgSrc(event.target.result);
                    };
                    reader.readAsDataURL(file);
                }
            }, [data]);
        
            return (
                <Card
                    cover={<img alt="example" src={imgSrc} />}                
                >
                </Card>
            );
        }
        return(
            <>
            
            </>
        );
    }

    const profileItems = [
        {key: '1', label:'Summary', children:<SummaryView {...props} />},
        {key: '2', label:'Mesh', children: <ViewerPage isSmallScreenSize={isSmallScreenHeight} {...props} />},
        {key: '3', label:'Measurements', children: <MeasurementView onBack={handleBack} isSmallScreenSize={isSmallScreenHeight} {...props} />},
    ]

    const MyProfile = (props) => {
        const [activeKey1, setActiveKey1] = useState('1');
        const handleBack1 = () => {
            setActiveKey1('1');
        };
        return(
            <>
            <Tabs type='card' onBack={handleBack1} activeKey={activeKey1} items={profileItems} onChange={setActiveKey1}/>
            </>
        );
    }

    const tabItems = [
        {key: '1', label:'Fit Assistance', children: <SizeRec onBack={handleBack} {...props} />},
        data && {key: '2', label:'My Profile', children: < MyProfile onBack={handleBack} {...props} />}
    ]


    return (
      <>
        {FabMenu}
        <Tabs size={'large'} tabPosition='top' activeKey={activeKey} items={tabItems} onChange={setActiveKey}/>
      </>
    );
}
