import { initializeApp } from "firebase/app";
import { getFirestore, doc, getDoc, setDoc, updateDoc, deleteDoc, serverTimestamp } from "firebase/firestore";
import { getStorage, ref, uploadBytesResumable, getDownloadURL, uploadBytes } from "firebase/storage";
import { getAuth,  GoogleAuthProvider, signInWithPopup, updateCurrentUser, signInAnonymously } from "firebase/auth";

export const firebaseConfig = {
  apiKey: "AIzaSyCKyg-YweDuJd490sKWDEjaOJJCIy1UTQ4",
  authDomain: "xperienceshoppingplugin.firebaseapp.com",
  projectId: "xperienceshoppingplugin",
  storageBucket: "xperienceshoppingplugin.appspot.com",
  messagingSenderId: "97803811983",
  appId: "1:97803811983:web:d6c0204b03c6543fb184fa",
  measurementId: "G-QD5QRZYW0J"
};

export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const auth = getAuth(app);
let thisUserUID = null;

export const LogInWithGoogle = async () => {
  try {
    // Initialize a new GoogleAuthProvider instance
    const provider = new GoogleAuthProvider();
    
    // Trigger Google Sign-In popup and await the result
    const userCredential = await signInWithPopup(auth, provider);
    
    // Get the logged-in user's UID
    thisUserUID = userCredential.user.uid;
    
    // Reference to the user's document in Firestore
    const userDocRef = doc(db, 'users', thisUserUID);
    
    // Try to get the user's document from Firestore
    const userDocSnap = await getDoc(userDocRef);
    
    // If the user document doesn't exist, create one
    if (!userDocSnap.exists()) {
      await setDoc(userDocRef, {
        lastLogin: serverTimestamp(),
        uuid: thisUserUID,
        completedProfile: false
      });
    } else {
      // Update the last login time if the user document already exists
      await updateDoc(userDocRef, {
        lastLogin: serverTimestamp(),
      });
    }
    
    return thisUserUID;
  } catch (error) {
    console.error("Error signing in with Google:", error);
  }
};

export const getUserDocument = async (userUID) => {
  try {
    const userDocRef = doc(db, 'users', userUID); // corrected from `thisUserUID` to `userUID`
    const userDocSnap = await getDoc(userDocRef);

    if (userDocSnap.exists()) {
      const userData = userDocSnap.data();
      return userData;
    }
    //  else {
    //   console.log("No such document! Creating one...");
      
    //   // Define the default fields for a new user
    //   const defaultUserData = {
    //     lastLogin: new Date(),
    //     uuid: userUID,
    //     completedProfile: false
    //   };
      
    //   // Create a new document with the default fields
    //   await setDoc(userDocRef, defaultUserData);

    //   // Return the default user data
    //   return defaultUserData;
    // }
  } catch (error) {
    console.error("Error getting user doc:", error);
    return null;
  }
};

export const updateFirebaseDoc = async (thisUserUID, data) => {
  if (thisUserUID) {
    const userDocRef = doc(db, 'users', thisUserUID);
    try {
      await updateDoc(userDocRef, data);
    } catch (error) {
      console.error("Error updating document:", error);
    }
  } else {
    console.error("User is not signed in. Cannot update document.");
  }
};

export const uploadAWSToFirebase = async (user, userData) =>{
  
  
  try {
    const newAvatarUrls = await Promise.all(userData.avatarUrl.map(async (url, index) => {
      // Create a Bytes data
      const response = await fetch(url);
      const blob = await response.blob();

      // Create a child reference
      const objRef = ref(storage, `avatars/${user}_00${index}.obj`);

      // Upload file to Firebase Storage
      await new Promise((resolve, reject) => {
        const uploadTask = uploadBytesResumable(objRef, blob);
        uploadTask.on('state_changed', 
          (snapshot) => {
            // Progress function
          }, 
          (error) => {
            // Handle unsuccessful uploads
            reject(error);
          }, 
          () => {
            // Handle successful uploads on complete
            resolve();
          }
        );
      });

      // Get download URL
      const downloadUrl = await getDownloadURL(objRef);
      return downloadUrl;
    }));

    console.log(newAvatarUrls);
    return newAvatarUrls;
  } catch (error) {
    console.error("Error uploading files:", error);
    throw error;
  }
};

export const signInWithGoogleAndReplace = async (thisUserUID) => {
  if (!thisUserUID) {
    console.error("No user is signed in.");
    return;
  }

  const provider = new GoogleAuthProvider();
  try {
    // Sign in with Google and get the Google user.
    const result = await signInWithPopup(auth, provider);
    const googleUser = result.user;

        // Check if the user is already logged in with Google
        const isAlreadyGoogleUser = googleUser.providerData.some(
          (providerInfo) => providerInfo.providerId === 'google.com'
        );
    
        if (isAlreadyGoogleUser) {
          console.log("User is already logged in with Google. No data will be replaced.");
        }

    // Read the anonymous user's Firestore document.
    const anonymousDocRef = doc(db, 'users', thisUserUID);
    const anonymousDocSnap = await getDoc(anonymousDocRef);

    if (!anonymousDocSnap.exists()) {
      console.error("Anonymous user document does not exist.");
    }

    // Create a new Firestore document for the Google user.
    const googleDocRef = doc(db, 'users', googleUser.uid);
    await setDoc(googleDocRef, {
      ...anonymousDocSnap.data(),
      lastLogin: serverTimestamp(),
      googleProfile: true,
      email: googleUser.email,
      name: googleUser.displayName,
      firstName: googleUser.displayName.split(' ')[0],
      uuid: googleUser.uid
    }, { merge: true });

    // Delete the anonymous Auth user identified by thisUserUID.
    updateCurrentUser(auth, googleUser);

    // Update thisUserUID to refer to the Google user.
    thisUserUID = googleUser.uid;
    // Delete the anonymous Firestore document.
    // await deleteDoc(anonymousDocRef);
    return thisUserUID;
  } catch (error) {
    console.error("Error in Google sign-in or data replacement:", error);
  }
};

export const saveDownloadLink = async (url) => {
  try {
    const response = await fetch(url);
    const blob = await response.blob();

    const storageRef = ref(storage, 'avatars/' + '-' + blob.name +'.obj');

    const uploadTask = uploadBytesResumable(storageRef, blob);

    uploadTask.on('state_changed', 
      (snapshot) => {},
      (error) => {
        console.error("Upload failed:", error);
      }, 
      async () => {
        try {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          await updateFirebaseDoc({avatarUrl: downloadURL});
          return downloadURL;
        } catch (error) {
          console.error("Error getting download URL:", error);
        }
      }
    );
  } catch (error) {
    console.error("Error uploading avatar:", error);
  }
};
