export const dummy = {
    avatarUrl: "https://s3.eu-central-1.amazonaws.com/mcme-prod-assets/mcme/238b9d12-9780-4470-98a2-6431293c51f7/055ea5f6-6778-4b2c-adb5-6193dafec9f1?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAUOXRLEJTMWLKGAKM%2F20230819%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-Date=20230819T072022Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&x-id=GetObject&X-Amz-Signature=387b686b4f643a7f80a8fc6b851e6724be0667b3193a200947c02a06cf2695fc",
     measurements : {
        Ankle_girth: 28.00851772493642,
        Armscye_girth: 41.52688276306926,
        Back_shoulder_width: 32.50602524361257,
        Bust_girth: 100.37130659317732,
        Foot_length: 26.289545744657516,
        Height: 170.0489640235901,
        Inside_leg_height: 78.80551218986511,
        Lower_arm_length: 26.107852162159737,
        Neck_base_girth: 40.849813595193204,
        Shoulder_length: 12.322888014281084,
        Thigh_girth: 63.62300002394531,
        Top_hip_girth: 97.60497883196389,
        Upper_arm_length: 35.44336991965978,
        Waist_girth: 87.29186626839463,
        Weight: 70.82925989162369
}}   

export async function startTask(result) {
    try {
        const formData = new FormData();
        formData.append('image', result.images[0]); // Assuming images[0] is a File object
        formData.append('result', JSON.stringify(result));

        const response = await fetch('https://xperience-shopping-api-805c97e9b811.herokuapp.com/start-task', {
            method: 'POST',
            body: formData
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        return data;

    } catch (error) {
        console.error('Error:', error.message);
        throw error;
    }
}

export async function retrieveAvatar(avatarId, userId) {
    try {
        const response = await fetch(`https://xperience-shopping-api-805c97e9b811.herokuapp.com/retrieve-avatar`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ avatarId, userId })
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        return data;

    } catch (error) {
        console.error('Error:', error.message);
        throw error;
    }
}

export async function downloadAvatar(avatarId) {
    try {
        const response = await fetch(`https://xperience-shopping-api-805c97e9b811.herokuapp.com/download-avatar`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ avatarId })
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        return data;

    } catch (error) {
        console.error('Error:', error.message);
        throw error;
    }
}

export async function askGPT(demoText) {
    try {
        const response = await fetch(`https://xperience-shopping-api-805c97e9b811.herokuapp.com/ask-gpt`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ demoText })
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        return data;

    } catch (error) {
        console.error('Error:', error.message);
        throw error;
    }
}

export async function getUserSummary(demoText) {
    try {
        const response = await fetch(`https://xperience-shopping-api-805c97e9b811.herokuapp.com/user-summary`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ demoText })
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        return data;

    } catch (error) {
        console.error('Error:', error.message);
        throw error;
    }
}

export async function editMeasurements(demoText) {
    try {
        const response = await fetch(`https://xperience-shopping-api-805c97e9b811.herokuapp.com/edit-measurements`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ demoText })
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        return data;

    } catch (error) {
        console.error('Error:', error.message);
        throw error;
    }
}

export function isValidJSON(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
    }

const makeRetrieveRequest = (avatarId, userId, updateFirebaseDoc, setAvatarData) => {
    let retries = 0;
    const maxRetries = 50;
    const retrieve = () => {
        retrieveAvatar(avatarId, userId, { timeout: 28900 }) // Set a timeout of 25 seconds for the request
        .then(data => {
            if (data.measurements && data.avatarUrl) {
                console.log('Avatar data:', data);
                // localStorage.setItem('XperienceAvatarData', JSON.stringify(data));
                const measurements = data.measurements;
                const avatarUrl = data.avatarUrl;
                setAvatarData(data);
                updateFirebaseDoc({completedProfile: true, screen: 'result', measurements: data.measurements, avatarUrl: data.avatarUrl, awsLinks: data.avatarUrl,  meshId: data.avatarId});
                getUserSummary(data.measurements);
                clearInterval(intervalId); // Stop the interval when the avatar is ready
                return {avatarUrl, measurements};
            } else if (retries >= maxRetries) {
                clearInterval(intervalId); // Stop the interval after max retries
            } else {
                retries++;
            }
        }).catch(error => {
            console.log('Error:', error.message);
            if (error.code === 'ECONNABORTED') {
                // Request timed out, call retrieve again immediately
                retrieve();
            } else if (error.response && error.response.status === 202) {
                // Avatar is not ready yet, continue polling
                retries++;
            } else {
                clearInterval(intervalId); // Stop the interval on other errors
            }
        });
    };
    // Make the initial request immediately
    retrieve();
    // Then start the polling
    const intervalId = setInterval(retrieve, 6500); // Poll every 27 seconds
};

export const getAvatarData = async (result, userId, updateFirebaseDoc, setAvatarData) => {
    try {
        const avatar = await startTask(result);
        console.log("Avatar inputs:", avatar);
        
        if (!avatar || !avatar.avatarId) {
            throw new Error("Invalid avatar object received from startTask.");
        }
        
        const avatarId = avatar.avatarId; // Assuming the avatar ID is directly returned from startTask
        const measurements = await makeRetrieveRequest(avatarId, userId, updateFirebaseDoc, setAvatarData);
        
        
        return measurements;
    } catch (error) {
        console.log('Error:', error.message);
        // Uncomment the following line if you would like to set an error state in your component
        // setError('Something went wrong. Please try again.');
        
        return null; // Return null or some error object if needed
    }
};