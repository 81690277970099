import React, { useState, useEffect } from 'react';
import './App.css';
import {CreateForm } from './Startboard';
import {LoadingPage } from './LoadingForm';
import { HelpPage } from './HelpPage';
import AppHeader from './Header';
import { getAvatarData, getUserSummary, editMeasurements } from './utils';
import { Layout, FloatButton, Modal, Button, ConfigProvider, theme, Typography, Card, notification, Input, QRCode } from 'antd';
import { SlidersOutlined, ReadOutlined, InfoCircleOutlined, BgColorsOutlined, EditOutlined, RedoOutlined } from '@ant-design/icons';
import { TabbedResults } from './Results';
import { db, auth, LogInWithGoogle, updateFirebaseDoc as updaterFunction, uploadAWSToFirebase, signInWithGoogleAndReplace, getUserDocument } from './firebaseConfig';
import ReactDOM from 'react-dom';
import firebase from "firebase/compat/app";
import "firebase/compat/storage";


function App(parentUrl) {
    const queryParameters = new URLSearchParams(window.location.search);
    const XperienceId = queryParameters.get('XperienceId');
    const isChromeExtension = queryParameters.get('isChromeExtension');
    const [showImage, setShowImage] = useState(true);
    const [darkMode, setDarkMode] = useState(false);
    const [screen, setScreen] = useState('form');
    const [avatarData, setAvatarData] = useState(null);
    const [showHelp, setShowHelp] = useState(false);
    const [result, setResult] = useState(null);
    const [summary, setSummary] = useState(null);
    const [open, setOpen] = useState(false);
    const [editText, setEditText] = useState('');
    const [user, setUser] = useState(null);
    const [loggedIn, setLoggedIn] = useState(false);
    const [userEmail, setUserEmail] = useState(null);
    const [userName, setUserName] = useState(null);
    const [clicked, setProfile] = useState();
    
    const { TextArea } = Input;
    const { Header, Content, Footer } = Layout;
    const { Title, Paragraph } = Typography;
    
    useEffect(() => {
      const updateAuthState = async () => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (user) {
                console.log(user);
                setUser(user.uid);
                setUserEmail(user.email);
                setUserName(user.displayName);
                setLoggedIn(true);
            }
        });
        return () => {
            unsubscribe();
        };
          
      };
      updateAuthState();
    }, []);

    useEffect(() => {
      const handleResize = () => {
        if (window.innerHeight < 500) {
          setShowImage(false);
        } else {
          setShowImage(true);
        }
      };
      // Initial check
      handleResize();

      // Listen for window resize events
      window.addEventListener('resize', handleResize);

      // Cleanup
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    useEffect(() => {
      if (XperienceId !== null) {
          console.log("XperienceId sent from query param", XperienceId);
          setUser(XperienceId);
          setLoggedIn(true); 
      }

      const fetchData = async () => {
          if (user) {
            console.log(user);
            const userData = await getUserDocument(user);
            if (userData) {
              console.log("userData:", userData);
  
              if (userData.measurements) {

                const obj = {
                    measurements: userData.measurements,
                    avatarUrl: userData.avatarUrl
                };
                setAvatarData(obj);
                setScreen('result');
                updateFirebaseDoc({screen: 'result'});

                if(!userData.measurement_summary){
                  getSummary(obj);
                }
              }

              if (userData.avatarUrl && userData.avatarUrl[0].indexOf("aws") !== -1) {
              
                const newAvatarUrls = await uploadAWSToFirebase(user, userData);
                const obj = {
                  measurements: userData.measurements,
                  avatarUrl: newAvatarUrls
                  };
                  setAvatarData(obj);
                  updateFirebaseDoc({avatarUrl: newAvatarUrls});
              }
            
              if (userData.darkMode !== undefined) {
                  setDarkMode(userData.darkMode);
              }
  
              if (userData.measurement_summary) {
                  setSummary(userData.measurement_summary);
              }
              
              if (userData.result !== undefined) {
                  setResult(userData.result);
              }
          }
        }
      };
      fetchData();
  }, [user]);
  
    function updateFirebaseDoc(data) {
        updaterFunction(user, data).then(() => {console.log('pushed to firebase:', data)});
    }
        
    function getSummary(data) {
        console.log(data.measurements);
        const measurementsString = Object.entries(data.measurements)
        .map(([key, value]) => `${key}: ${value.toFixed(2)}`)
        .join(', ');

        const demoText = `Measurements (cm): ${measurementsString}`;

        getUserSummary(demoText).then((result) => {
            let parsedRecommendation;
          
            // Check if result is a string
            if (typeof result.assistantMessage === 'string') {
              // Check if the string is a representation of an array (starts with '[' and ends with ']')
              if (result.assistantMessage.startsWith('[') && result.assistantMessage.endsWith(']')) {
                parsedRecommendation = JSON.parse(result.assistantMessage);
              } else {
                // If it's a regular string, split it into an array by sentence period.
                parsedRecommendation = result.assistantMessage.split('. ');}
            } else if (Array.isArray(result.assistantMessage)) {
              // If it's already an array, no need to parse
              parsedRecommendation = result.assistantMessage;
            }
          
            setSummary(parsedRecommendation);
            updateFirebaseDoc({measurement_summary: parsedRecommendation});
          });
          
    }

    function CloseButton() {
        // Save the state to localStorage before closing
        localStorage.setItem('XperienceDarkMode', JSON.stringify(darkMode));
        localStorage.setItem('XperienceScreen', JSON.stringify(screen));
        localStorage.setItem('XperienceAvatarData', JSON.stringify(avatarData));
        localStorage.setItem('XperienceShowHelp', JSON.stringify(showHelp));

        const root = document.getElementById('xperience-plugin');
        if (root) {
            document.body.removeChild(root);
        }
    }

    const toggleDarkMode = () => {
        setDarkMode(!darkMode);
        updateFirebaseDoc({darkMode: !darkMode});
    };
 
   const resetProfile = () => {
        setScreen('form');
   }

    const handleFormSubmit = async function  (formData){
      console.log(user);
        console.log("handleFormSubmit called with formData:", formData);
        var obj = {};
        obj = formData;
        console.log(obj);
        setResult(obj);
        getAvatarData(obj, user, updateFirebaseDoc, setAvatarData);
        setScreen('result');
    };

    const showModal = () => {
        setOpen(true);
    };

    const [api, contextHolder] = notification.useNotification();

    const openNotification = (placement) => {
        api.info({
          message: `Measurements updated.`,
          description:
            'Your measurements have been updated accordingly. Visit My Profile to review.',
          placement,
        });
      };
    
    const handleOk = () => {    
        // Update the summary state
        const constructor = `{"measurements": ${JSON.stringify(avatarData.measurements)}, "Message / Adjustment Criteria": "${editText}"}. Return new measurements object.`;
        editMeasurements(constructor).then((result) => { 
            console.log("Edit Measurements Result:", result.assistantMessage);
            const measurements = result.assistantMessage;
            setAvatarData({...avatarData, measurements: measurements});
            updateFirebaseDoc({ measurements : measurements, editMessage: editText  });
            openNotification('bottomLeft');
        });
        // Close the modal
        handleCancel();
    };
    
    const handleCancel = () => {
        setOpen(false);
    };

    const handleGoogleSignIn = async () => {
      console.log("User information:", user);
      console.log("Attempting to sign in with Google account.");
      const thisUserId = await LogInWithGoogle().then((result) => {
        console.log("Logged in with Google account.", result); 
        // localStorage.setItem("XperienceId", result);
        setUser(result);
        // Set user ID to localStorage
        return result;
      }
   );
   return thisUserId;
  };
  

  const handleRegister = async () => {
      console.log("User information:", user);
      console.log("Attempting to sign in with Google account.");
      const thisUserId = await LogInWithGoogle().then((result) => {
        console.log("Logged in with Google account.", result); 
        // localStorage.setItem("XperienceId", result);
        setUser(result);
        // Set user ID to localStorage
        return result;
      }
   );
   return thisUserId;
  };

    const FabMenu = (darkMode) => (
        <>
          <FloatButton.Group
            trigger="hover"
            type= {darkMode ? "primary" : "default"}
            style={{
                right: 40,
            }}
            icon={<InfoCircleOutlined />}
          >
            <FloatButton onClick={setShowHelp} icon={<ReadOutlined />} tooltip="About Plugin"/>
            <FloatButton onClick={toggleDarkMode} icon={<BgColorsOutlined />} tooltip="Toggle darkmode"/>
            {avatarData?(<><FloatButton onClick={showModal} icon={<SlidersOutlined />} tooltip="Edit My Measurements"/>
            <FloatButton onClick={resetProfile} icon={<RedoOutlined />} tooltip="Redo Body Scan"/></>):<></>}
          </FloatButton.Group>
        </>
      );

    return (
      <ConfigProvider
            theme={{
                token: {
                    colorPrimary:  darkMode ? "#A885D8" : "#222222",
                    colorInfo: "#000000",
                    colorSuccess: "#4c4c4c",
                    colorLink: "#722ED1",
                    colorTextPlaceholder: '000000',
                    fontSize: 18, 
                    },
                    components: {
                    Typography: {
                        algorithm: true
                    }
                    },
            algorithm: darkMode ? theme.darkAlgorithm : theme.defaultAlgorithm,
        }}>
            {contextHolder}
        <Layout className='XperienceContainer' style={{ color: darkMode ? '#ffffff' : '#222222', minHeight:'700px', background: darkMode ? '#222222' : '#fff' }}>
        {/* <AppHeader 
            darkMode={darkMode} 
            toggleDarkMode={toggleDarkMode} 
            setShowHelp={setShowHelp} 
            CloseButton={CloseButton} 
        /> */}
        <>
      <Modal
        open={open}
        title="Edit My Measurements"
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
            <br></br>,
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleOk}>
            Edit
          </Button>,
        ]}
      >
        <Paragraph>Where did we go wrong? Edit your measurements by describing below the changes you'd like to make. </Paragraph>
        <TextArea
          value={editText}
          onChange={(e) => setEditText(e.target.value)}
          showCount={true}
          allowClear={true}
          aria-multiline={true}
          rows={4}
          placeholder="My chest is actually 37.5 inches and my shoe size 11. OR try, I want a tighter fit, take it down slighly."
        />
        <br></br>
        
      </Modal>
     </>
     <Content style={{ paddingLeft: '20px', paddingRight: '20px',  width: '100%', wordWrap: 'break-word'}}>
          { !loggedIn ?
          <>
          <br></br>
          <div style={{height:65, marginLeft: '-50px', maxWidth:'100%'}}>
          <img src='https://uploads-ssl.webflow.com/61a5cdfe7fc055e2d1b7141b/61a5cffcf8821a22d65a7a2b_logo-p-1600.png' height='100%' alt='Xperience Logo'/>
          </div>
            <br></br>
            <Card title="Sign in / Register">
              <Paragraph>Please login in with Google to start getting size recommendations across online shops.</Paragraph>
             <center>
              <>
              <Button type="primary" onClick={handleRegister}>Login with Google</Button>
              <br></br><br></br><br></br>
              <img
                style={{ maxWidth: '100%', bottom: 0,height: 'auto', marginBottom: '-30px' }}
                src="https://uploads-ssl.webflow.com/61a5cdfe7fc055e2d1b7141b/61b3c01692f7a6723b9d0a09_Frame%2014-p-2000.png"
                alt="Xperience Lottie"
              />
              </>
              </center>
            </Card>
            <br></br>
          </> 
          :
          <>
           {user && (
            <>
             {screen === 'form'  && isChromeExtension ?
                <>
                <br/> <br/>
                  <Card title="Set up your profile">
                    <Paragraph>Submit a reference photo and a couple inputs to predict your measurements and generate a 3D avatar.</Paragraph>
                    <center>
                      <QRCode value={'https://xperienceshopping.app?XperienceId=' + user } /> 
                    </center>
                  </Card>
                </> 
              : 
              <>
                { screen === 'form'  &&
                  <CreateForm handleGoogleSignIn={handleGoogleSignIn} user={user} onSubmit={handleFormSubmit} isChromeExtension={isChromeExtension}/>
                }
                { screen === 'result' &&  
                  <TabbedResults 
                    FabMenu={<FabMenu 
                      darkMode={darkMode} 
                      toggleDarkMode={toggleDarkMode} 
                      setShowHelp={setShowHelp}/>} 
                    user={user} result={result} createProfile={handleGoogleSignIn} clicked={clicked} userEmail={userEmail} data={avatarData} parentUrl={parentUrl} summary={summary} onBack={() => {setScreen('result')}} darkMode={darkMode} />
                  }
              </>
              }
            </>
          )}
        </>
        } 
        </Content>
        {/* <Footer style={{ textAlign: 'center', bottom: 0 }}> All Rights Reserved | Xperience Shopping ©2023</Footer> */}
        </Layout>      
        </ConfigProvider>
    );
}

export default App;