import React, { useState, useEffect } from 'react';
import { Progress, Card, Space, Button, Typography, Input} from 'antd';
import { TypeAnimation } from 'react-type-animation';
import './App.css';
import { askGPT } from './utils';
import { SafetyCertificateTwoTone, BulbTwoTone, ThunderboltTwoTone, CheckCircleOutlined } from '@ant-design/icons';
import firebase from "firebase/compat/app";
import {auth, db, thisUser} from './App';
import { updateFirebaseDoc } from './firebaseConfig';
import { Texts, LoadingPage } from './LoadingForm';

const { Paragraph, Title } = Typography;

const images1 = ['https://media4.giphy.com/media/26gsuUjoEBmLrNBxC/giphy.gif?cid=ecf05e47w3zeu3g286en99oj65ux3qsqpqptng4jpoi4t13i&ep=v1_gifs_search&rid=giphy.gif&ct=g',
'https://media1.giphy.com/media/e1CoBCiWvIB2w/giphy.gif?cid=ecf05e47hn68cocxgzv46tzfna5ttagd9n96w95tj7dph0ao&ep=v1_gifs_related&rid=giphy.gif&ct=g',
'https://media3.giphy.com/media/3oFzmpa8AZVJ2sgPmw/giphy.webp?cid=ecf05e47e2xzgijhmdas73gxtcithwye8cgemu0e1cxvce56&ep=v1_gifs_related&rid=giphy.webp&ct=g',
'https://media1.giphy.com/media/26tn2q3F0oLQlZZGU/200w.webp?cid=ecf05e47e2xzgijhmdas73gxtcithwye8cgemu0e1cxvce56&ep=v1_gifs_related&rid=200w.webp&ct=g', 'https://media2.giphy.com/media/B8dUiWJmQi4KCQhS9x/200w.webp?cid=ecf05e47e2xzgijhmdas73gxtcithwye8cgemu0e1cxvce56&ep=v1_gifs_related&rid=200w.webp&ct=g', 'https://media1.giphy.com/media/dXXujUmfpS3XG/200w.webp?cid=ecf05e47e2xzgijhmdas73gxtcithwye8cgemu0e1cxvce56&ep=v1_gifs_related&rid=200w.webp&ct=g', 'https://media4.giphy.com/media/l3fQ8QnhKxbBUB5oA/200w.webp?cid=ecf05e47e2xzgijhmdas73gxtcithwye8cgemu0e1cxvce56&ep=v1_gifs_related&rid=200w.webp&ct=g'];  

export function SizeRec({ data, user, parentUrl, createProfile, darkMode, userEmail, clicked}) {

  const [recommendation, setRecommendation] = useState(null);
  const productLink = Object.entries(parentUrl).map(([key, value]) => `${value}`);
  const demoLinks = ['http://localhost:3000/?XperienceUser=6npZWIzCgwQ1XlA2zY67C3Y0zmA2','xperience-shopping.netlify.app','https://xperience-shopping.webflow.io/', 'https://xperienceshopping.io/', 'https://xperienceshopping.app'];
  const [isUrlInArray, setIsUrlInArray] = useState(false);

  useEffect(() => {
    let isUrlInArray = false;
    for (let i = 0; i < demoLinks.length; i++) {
      if (demoLinks[i].includes(productLink[0])) {
        isUrlInArray = true;
        setIsUrlInArray(isUrlInArray);
        console.log("true");
        break;
      }
    }
  }, [productLink]);

useEffect(() => {
    function getRecommendation() {
        console.log(productLink[0]);

        function isValidJSON(str) {
            try {
                JSON.parse(str);
            } catch (e) {
                return false;
            }
            return true;
        }

        if(data.measurements){
        const measurementsString = Object.entries(data.measurements)
            .map(([key, value]) => `${key}: ${value.toFixed(2)}`)
            .join(', ');

        const demoText = `Product url: ${productLink[0]}, Measurements (cm): ${measurementsString},  Fit Preference: ${data.fitPreference}, Shoe Size: ${data.shoeSize}`;
       
        askGPT(demoText).then((result) => {
            console.log(result);
            if (isValidJSON(result.assistantMessage)) {
                const parsedRecommendation = JSON.parse(result.assistantMessage);
                setRecommendation(parsedRecommendation);
                console.log('IS VALID JSON: ' + parsedRecommendation);
            } else {
                setRecommendation(result.assistantMessage);
                console.log('IS Not VALID JSON: ' + result.assistantMessage);
            }
        });
        updateFirebaseDoc(user, {recommendation: { url: productLink[0], gptPrompt: demoText, response: recommendation}});
        }

        
    }

    if (!isUrlInArray) { 
        if (data){
            getRecommendation();
        }
    }
}, [parentUrl, data, isUrlInArray]);


  const [percent, setPercent] = useState(0);

  useEffect(() => {
      const interval = setInterval(() => {
        setPercent(prevPercent => {
          if (prevPercent >= 100) {
            clearInterval(interval);
            return 100;
          }
          return prevPercent + 0.6; // Increment by 0.1111 to reach 100 in 90 seconds
        });
      }, 75); // Update every 100ms
  
      return () => clearInterval(interval); // Cleanup interval on component unmount
    }, []);
  
    const strokeColor = darkMode ? { '0%': '#ffffff', '100%': '#A885D8' } : { '0%': '#000000', '100%': '#A885D8' };

  return (
    <>
    {!data ? <LoadingPage /> 
    : <>
      { isUrlInArray ? 
        <>
          <Card
            hoverable
            title={ userEmail ? "Download Chrome Extension" : "Save Profile"}>
           { !userEmail ? <>
            <Paragraph>We've calculated your measurements and you're ready to get recommendations! You may now review them and explore your 3D avatar in My Profile.</Paragraph>
            <Paragraph> Save your profile to use this plugin across stores:</Paragraph>
            <Space><Button type='primary' disabled={clicked} onClick={createProfile}>Save Profile</Button></Space>
            </>
            :
            <>
            <Paragraph>Get size recommendations across all shops today, using our Chrome Extension. Install the extension on your browser and login, your profile is ready to go.</Paragraph>
            <Space><Button href='https://chrome.google.com/webstore/detail/xperience-shopping-ai-fit/onlnboeddkhfllpekopcmijemcniijlh?hl=en&authuser=0' target='blank' type='primary'>View in Chrome Store</Button></Space>
            </>
          }
        </Card>
        <br/>
        {userEmail && <Card hoverable title={
              <center><div style={{ display: 'flex', alignItems: 'center', justifyContent:'center', whiteSpace: 'pre-line', marginTop: 10, wordWrap: 'break-word', overflow: 'wrap' }}>
                <CheckCircleOutlined style={{ marginRight: '8px', color: '#52c41a' }} /> Connected to Google</div></center>}>
              <center><Paragraph>{userEmail}</Paragraph></center> 
        </Card> }
        </>
      : 
        <>
          {recommendation ? (
            recommendation.ProductName ? (
              <>
                <Card
                  hoverable
                  title={
                    <div style={{ display: 'flex', alignItems: 'center', whiteSpace: 'pre-line', marginTop: 10, wordWrap: 'break-word', overflow: 'wrap' }}>
                      <SafetyCertificateTwoTone twoToneColor="#52c41a" style={{ marginRight: '8px' }} />
                      {recommendation.ProductName + ": " + recommendation.Size}
                    </div>
                  }>
                  {recommendation.Summary}<br /><br />Confidence Score: {recommendation.Confidence} %
                </Card>
                <br />
                {recommendation.Reason ? (
                  <div>
                    <Card
                      hoverable
                      type="inner"
                      title={
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <ThunderboltTwoTone theme="twoTone" twoToneColor='#d4af37' style={{ marginRight: '8px' }} /> AI Insights</div>
                      }>
                      {recommendation.Reason.map((reason, index) => (
                        <div style={{ maxWidth: '100%' }} key={index}>
                          <TypeAnimation
                            style={{
                              display: 'block',
                            }}
                            wrapper="div"
                            omitDeletionAnimation="true"
                            sequence={['→ ' + reason]}
                          />
                          <br />
                        </div>
                      ))}
                    </Card>
                  </div>
                ) : null}
              </>
            ) : (
              <>
                <Card
                  hoverable
                  title={
                    <div style={{ display: 'flex', alignItems: 'center', whiteSpace: 'pre-line', marginTop: 10, wordWrap: 'break-word', overflow: 'wrap' }}>
                      <SafetyCertificateTwoTone theme="twoTone" twoToneColor="#52c41a" style={{ marginRight: '8px' }} /> No Size Chart Found
                    </div>
                  }>
                  {recommendation}
                </Card>
              </>
            )
          ) : (
            <center>
              <Card>
              <Progress type="circle" size='default' percent={Math.round(percent)} status="active" strokeColor={strokeColor} />
              <Paragraph>Fetching your size recommendation...</Paragraph>
                </Card>
              {/* <Card title="Please wait">
                <img width={'100%'} height={'100%'} style={{objectFit:'cover'}} alt='loading animation' src='https://media4.giphy.com/media/26gsuUjoEBmLrNBxC/giphy.gif?cid=ecf05e47w3zeu3g286en99oj65ux3qsqpqptng4jpoi4t13i&ep=v1_gifs_search&rid=giphy.gif&ct=g'/>
              </Card> */}
            </center>
          )}
          <br /><br />
          <center>
          </center>
        </>
      }
    </>
}</>);
}