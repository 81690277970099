// AppHeader.js
import React from 'react';
import { Switch } from 'antd';
import { BulbOutlined, QuestionCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Layout } from 'antd';
import Title from 'antd/es/typography/Title';
const { Header } = Layout;


function AppHeader({ darkMode, toggleDarkMode, setShowHelp, CloseButton }) {
    return (
        <Header style={{ padding: '5px', flexShrink: 1, backgroundColor: darkMode? "#000000" : "#f2f2f2"}}>
            <div style={{ display: 'flex', maxHeight: '50px', justifyContent: 'space-between' }}>
                
                <div style={{ flexShrink: 1, display: 'flex', alignItems: 'center', maxWidth: '70%' }}>
                    {/* <img 
                       
                        src='https://uploads-ssl.webflow.com/61a5cdfe7fc055e2d1b7141b/61a5cffcf8821a22d65a7a2b_logo.png' 
                        alt='LOGO'
                    /> */}
                    <Title  style={{ objectFit: 'contain', marginLeft:15, width: 'auto', height: '100%' }} level={4}>MyFit Analyzer</Title>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginRight:'10px', justifyContent: 'space-between', flexShrink: 1 }}>
                    <Switch 
                        checked={darkMode} 
                        onChange={toggleDarkMode} 
                        checkedChildren={<span><BulbOutlined style={{color:'#f5f5f5'}}/></span>}
                        unCheckedChildren={<span><BulbOutlined style={{color:'#f5f5f5'}}/></span>}
                    />
                    <QuestionCircleOutlined 
                        label='help'
                        style={{ marginLeft: '10px', fontSize: '20px', marginRight: '15px', color: '#319795' }} 
                        onClick={() => setShowHelp(true)} 
                    /> 
                    <CloseCircleOutlined 
                        style={{ color: 'red', fontSize: '20px'}} 
                        onClick={() => CloseButton()} 
                    />
                </div>
            </div>
        </Header>
    );
}

export default AppHeader;
