import React from 'react';
import './App.css';
import {  Button, Card, Typography } from 'antd';
const { Title, Paragraph } = Typography;

export  function HelpPage({ onBack }) {
    return (
        <>
        <Button type="dashed" onClick={onBack}>Back</Button>
        <br></br><br></br>
        <Card title="About Xperience Shopping">
          <Paragraph>We use artificial intelligence to make online shopping more personalized and more sustainable. By getting the right fit, we help fashion brands reduce their rate of returns, carbon footprint and overall waste. </Paragraph>
          <Button type="primary">Learn more</Button>
        </Card>
        <br></br>
        <Card title="About this plugin">
          <Paragraph>This site hosts Xperience Shopping virtual avatar builder & measurements calculator plugin. It makes calls to our API on your behalf. Simply inject this plugin into your website.
            The plugin is used by placing the script in head of js site to popup Xperience Shopping wizard plugin. </Paragraph>
          <Button type="primary">Learn more</Button>
        </Card>
        <br></br>
        <Card title="Credits & Acknowledgements">
          <Paragraph>Avatar creation powered by Meshcapade GmbH ©2023. Avatars made via Xperience Shopping make anonymous models through license agreement with Meshcapade GmbH.</Paragraph>
          <Button type="primary">Learn more</Button>
        </Card>
        <br></br>
        <Card title="Privacy">
          <Paragraph>We do not save your input photos or collected any more personal data than that which is inputted or generated. Key factors to predict your measurements are height, weight, sex and a clear reference photo. This data is not saved. If you choose, you may create a profile to store your measurements for use across other apparel sites. </Paragraph>
          <Button type="primary">Learn more</Button>
        </Card>
        </>
    );
}