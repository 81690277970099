/* global chrome */
import React, { useState }from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import reportWebVitals from './reportWebVitals';

const Wave = () => {
  const [isWavesCentered, setIsWavesCentered] = useState(false);

  const toggleWavesSize = () => {
    setIsWavesCentered((prevState) => !prevState);
  };

  return (
    <>
      <section className="hero">
        <div className={`hero__waves ${isWavesCentered ? 'is-centered' : ''}`} />
        <h1 className="hero__title">Welcome to React</h1>
        <button className="hero__button" onClick={toggleWavesSize}>
          Toggle Waves Size
        </button>
      </section>
      {/* <section className="content">
        <p className="content__paragraph">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
          tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
          veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
          commodo consequat.
        </p>
        <p className="content__paragraph">
          Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
          dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
          proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
        </p>
      </section> */}
    </>
  );
};

let root;
const parentUrl = window.location.href;

// Function to initialize the React app
window.launchXperience = function() {
    console.log("Parent URL:", parentUrl);
    // Create close function
    //toDo: add close button
    // plugin html id
    root = document.createElement('div');
    root.id = 'xperience-plugin';
    document.body.appendChild(root);
    const isChromeExtension = false;
    ReactDOM.render(React.createElement(App, { parentUrl: parentUrl, isChromeExtension: isChromeExtension }), root);
};

const XperiencePlugin = function() {
    console.log("Parent URL:", parentUrl);
    root = document.createElement('div');
    root.id = 'xperience-plugin';
    document.body.appendChild(root);
    const isChromeExtension = false;
    ReactDOM.render(React.createElement(App, { parentUrl: parentUrl, isChromeExtension: isChromeExtension }), root);
};

const params = new URLSearchParams(window.location.search);
const isChromeExtension = params.get('isChromeExtension') === 'true';
console.log("Welcome to Xperience Shopping's Chrome Extension. If you've found this message, seems like you know a thing or two. If you want to look at source, go to github.com/cameronking4/XperienceShopping-ChromeExtension", isChromeExtension);
// Check if running in a Chrome extension popup
if (isChromeExtension) {
    if (!root) {
        root = document.createElement('div');
        root.id = 'xperience-plugin';
        document.body.appendChild(root);
    }
    ReactDOM.render(React.createElement(App, { parentUrl: parentUrl, isChromeExtension: true }), root);
}else{
    XperiencePlugin();
}

reportWebVitals();
