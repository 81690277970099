import './App.css';
import React, { useRef, useState, useEffect } from 'react';
import * as THREE from 'three';
import { OBJLoader } from 'three/addons/loaders/OBJLoader.js';
import { OrbitControls } from 'three/addons/controls/OrbitControls.js';
import { Button, Card, Typography } from 'antd';

const { Paragraph } = Typography;

export function ViewerPage({ data, darkMode, isSmallScreenSize }) {
    const url = data.avatarUrl[0];
    const mountRef = useRef(null);
    const [scene, setScene] = useState(null);
    

    useEffect(() => {
        const width = mountRef.current.clientWidth;
        const height = mountRef.current.clientHeight;

        // Create scene, camera, and renderer
        const newScene = new THREE.Scene();
        const camera = new THREE.PerspectiveCamera(75, width / height, 0.1, 1000);
        camera.position.z = 5;
        camera.position.y = -2;

        const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true});
        renderer.setClearColor(0x000000, 0); // The second parameter is the alpha (0 for full transparency)
        renderer.setSize(width, height);
        mountRef.current.appendChild(renderer.domElement);

        // Add lights
        const ambientLight = new THREE.AmbientLight(0xffffff, 1);
        newScene.add(ambientLight);
        const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
        directionalLight.position.set(1, 1, 1);
        newScene.add(directionalLight);

        // Controls
        const controls = new OrbitControls(camera, renderer.domElement);
        controls.addEventListener('change', () => renderer.render(newScene, camera));

        // const textureLoader = new THREE.TextureLoader();
        // textureLoader.load('https://raw.githubusercontent.com/mrdoob/three.js/master/examples/textures/sprites/spark1.png', (texture) => {
        // console.log('textureloaded')
        // Instantiate a loader
        const loader = new OBJLoader();
        // Load the model
        loader.load(
             url,
            (object) => {

                object.traverse(function(child) {
                  
                        const material = new THREE.PointsMaterial({ color: darkMode ? 0xffffff : 0x222222, size: 0.0117, transparent: true});
                        const points = new THREE.Points(child.geometry, material);
                        points.scale.set(5, 5, 5);
                        points.position.y = -4;
                        newScene.add(points);
                        renderer.render(newScene, camera); // Ensure rendering after model is loaded
                    
                });
                
            },
            (xhr) => {
                console.log((xhr.loaded / xhr.total * 100) + '% loaded');
            },
            (error) => {
                console.error('Error loading the OBJ file:', error);
            }
        );
        // }, 
        // undefined, // onProgress can be undefined if you don't need it
        // (error) => {
        //     console.error('Error loading texture:', error);
        // });
        setScene(newScene);

        return () => {
            if (mountRef.current && renderer.domElement) {
                mountRef.current.removeChild(renderer.domElement);
            }
        };
      
    }, [url, darkMode]);

    const downloadFile = async (url) => {
        try {
            const response = await fetch(url);
            const blob = await response.blob();
            const blobUrl = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = blobUrl;
            a.download = 'XperienceAvatar.obj';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        } catch (error) {
            console.error('Error downloading the file:', error);
        }
    };

    return (
        <div>
            <Card>
                <div style={{ width: '100%',  height: isSmallScreenSize ? '250px' : '450px' }} ref={mountRef}></div>
            </Card>
            <br />
            <Card title='Your virtual avatar'>
                <Paragraph>Drag, Zoom & Pan (ctrl) to explore your virtual avatar</Paragraph>
                <Button onClick={() => downloadFile(url)}>Download generated 3D file</Button>
            </Card>
            <br />
        </div>
    );
}
