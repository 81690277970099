import React, { useState } from 'react';
import { Table, Button, Switch } from 'antd';

export function MeasurementsTable({ measurements, isSmallScreenSize }){
    const [isInches, setIsInches] = useState(true);

    const convertToInches = (cm) => cm / 2.54;

    const dataSource = Object.entries(measurements).map(([key, value], index) => ({
        key: index,
        measurement: key,
        value: isInches ? convertToInches(value).toFixed(2) : value.toFixed(2)
    }));

    const columns = [
        {
            title: 'Measurement',
            dataIndex: 'measurement',
            key: 'measurement',
        },
        {
            title: isInches ? 'Value (inches)' : 'Value (cm)',
            dataIndex: 'value',
            key: 'value',
        },
    ];

    return (
        <div className="table-container">
            <Table dataSource={dataSource} columns={columns} fixed={'top'} pagination={{ position: ['bottomCenter'], pageSize: isSmallScreenSize ? 5 : 9}} />
            <div className="toggle-container">
                <span>cm </span>
                <Switch checked={isInches} onChange={() => setIsInches(!isInches)} />
                <span> inches</span>
            </div>
        </div>
    );
};
